import { ReactElement, useMemo } from 'react';
import { MarkerClusterer } from 'react-kakao-maps-sdk';
import RestaurantMarkers from './RestaurantMarkers';

import GwanggyoData from '../../../../constants/gwanggyo_data.json';

import { useFormContext } from 'react-hook-form';

function RestaurantList(): ReactElement {
  const { watch } = useFormContext();
  const filterValue = watch('filter');

  const restaurantList = useMemo(() => {
    return [...GwanggyoData];
  }, [filterValue?.value]);

  return (
    <>
      {restaurantList.map((item) => {
        return (
          <RestaurantMarkers
            key={'marker_' + item.type + '_' + item.index + '_' + item.title}
            position={{ lat: parseFloat(item.y), lng: parseFloat(item.x) }}
            item={item}
          />
        );
      })}
    </>
  );
}

export default RestaurantList;
