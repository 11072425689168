// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const envString = process.env?.REACT_APP_ENV as 'prod' | 'dev';
const isProd = envString === 'prod';

const firebaseConfig = isProd
  ? {
      apiKey: 'AIzaSyBsCMi5vHT97s8g11dch3AuL6DrMaGI5bg',
      authDomain: 'matzip-group.firebaseapp.com',
      projectId: 'matzip-group',
      storageBucket: 'matzip-group.appspot.com',
      messagingSenderId: '1010367540282',
      appId: '1:1010367540282:web:d9608451adc35fb9fcd903',
      measurementId: 'G-9F7JBCTVHM',
    }
  : {
      apiKey: 'AIzaSyB-aJCVq3S3p3rq9k3FZHjJEr-dpWzy5iU',
      authDomain: 'personal-proj-alpha.firebaseapp.com',
      projectId: 'personal-proj-alpha',
      storageBucket: 'personal-proj-alpha.appspot.com',
      messagingSenderId: '371202041319',
      appId: '1:371202041319:web:9947587dea746504c18942',
      measurementId: 'G-YJ6YRGEDHD',
    };

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
