import styled from '@emotion/styled';
import { logEvent } from '@firebase/analytics';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { BottomSheet } from 'react-spring-bottom-sheet';
import { analytics } from '../utils/firebase';

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ACCESS_CODE = '11011';

const AccessBottomSheet = ({ open, setOpen }: Props) => {
  const { watch, setValue } = useFormContext();

  const accessHander = () => {
    logEvent(analytics, 'click_access_button');
    if (watch('accessCode') === ACCESS_CODE) {
      setOpen(true);
    } else {
      logEvent(analytics, 'click_access_button_fail');
      alert(
        '접속코드가 일치하지 않습니다.\n접속코드를 모르신다면 광교 맛집 당근모임에 맛집 추천글을 1개 이상 작성하시면 접속코드를 얻으실 수 있어요.'
      );
    }
  };
  return (
    <BottomSheet open={open}>
      <Wrapper>
        <Title>안내</Title>
        <Contents>
          이 서비스는 <b>🍲🍛광교/영통 맛집 모음🥘🍝</b> 모임에서 이웃들에게{' '}
          <span style={{ color: 'red', fontWeight: '600' }}>1개 이상의 맛집을 공유</span>하셔야
          사용하실 수 있습니다.
          <br />
          <br />
          공유하셨다면 아래에 <b>접속코드</b>를 기입해주세요.
          <br />
          <Input
            value={watch('accessCode') ?? ''}
            onChange={(e) => setValue('accessCode', e.target.value)}
            placeholder="이곳을 눌러 접속코드를 입력해주세요"
          />
          <br />
        </Contents>

        <Button onClick={accessHander}>이용하기</Button>
        <SubButton
          onClick={() => {
            window.open('https://www.daangn.com/kr/groups/QdY69Nj0', '_blank');
          }}
        >
          모임으로 이동
        </SubButton>
      </Wrapper>
    </BottomSheet>
  );
};

export default AccessBottomSheet;

const Wrapper = styled.div`
  width: 100%;

  padding: 20px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 14px;
`;

const Contents = styled.div`
  width: 100%;
  font-size: 14px;
  white-space: pre-line;
  line-height: 1.4;
  word-break: keep-all;
  margin-bottom: 10px;
`;

const Input = styled.input`
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0 15px 0;
  border-radius: 10px;
  border: 1px solid #ff9a3b;
`;

const Button = styled.div`
  width: 100%;
  padding: 14px 0;
  margin: 5px 0 0 0;
  border-radius: 10px;
  background-color: #ff9a3b;
  color: white;
  font-weight: bold;
  font-size: 16px;
  text-align: center;
  cursor: pointer;
`;

const SubButton = styled.div`
  width: 100%;
  padding: 14px 0;
  color: #ff9a3b;
  font-weight: 500;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
`;
